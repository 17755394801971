import React from 'react';
import StatementOfFaith from '../components/HomePage/StatementOfFaith';

const SOFPage = () => {
  // TODO: remove brs, use css margin
  return (
    <>
      <br />
      <StatementOfFaith />
    </>
  );
}

export default SOFPage;
