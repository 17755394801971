import React from 'react';
import Hero from '../components/HomePage/Hero';
import Anthology from '../components/HomePage/Anthology';
import BibleVerse from '../components/HomePage/BibleVerse';
import StatementOfFaith from '../components/HomePage/StatementOfFaith';
import TimeOfService from '../components/HomePage/TimesOfService';
import Address from '../components/HomePage/Address';
import Information from '../components/HomePage/Information';
import Schedule from '../components/HomePage/Schedule';
import Pastor from '../components/HomePage/Pastor'

const HomePage = () => {
  return (
    <>
      <Hero />
      <BibleVerse />
      <Schedule />
      <Pastor />
      <Anthology />
    </>
  );
}

export default HomePage;
