import React from 'react';
import '../static/BibleVerse.css';


const BibleVerse = () => {
  return (
    <section className='darkSection'>
      <div id="top-pad" className='aboutSummary'>
        <h2 className='verse-headings' style={{ textAlign: "center" }}>Equipping Godly men and Godly women, </h2>
        <h2 className='verse-headings' style={{ textAlign: "center" }}>To build Godly families and raise Godly children</h2>
        <h6 style={{ textAlign: "center" }}><i>We will not hide them from their children, but tell to the coming generation the glorious deeds of the Lord, and his might, and the wonders that he has done. <br />- Psalm 78:4</i></h6>
      </div>
    </section>
  );
}

export default BibleVerse;
