import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// const profile = require('../images/Hero.jpg')
import '../static/Hero.css';

const Hero = () => {
  return (
    <section class="hero" id="hero" >
      <h1 class="text-center context ">

        <span id="first">Welcome to</span>
        <br />
        <span id="second">Brooklyn Community Christian Church</span>
        <br /><br />

        <div className="same">
          <div>
            <Link to='/statement-of-faith' class="nav-link" aria-current="page" >
              <button class="button button1">Our Beliefs</button>
            </Link>
          </div>
          <div className="divider"></div>
          <div>
            <HashLink smooth to='/#schedule' class="nav-link" aria-current="page" >
              <button class="button button2">Schedule</button>
            </HashLink>
          </div>
        </div>
      </h1>
    </section>
  );
}

export default Hero;
